import { graphql, PageProps } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Badge, Col, Container, ListGroup, Row } from 'react-bootstrap'
import { Heading } from '../components/Heading'
import { Layout } from '../components/Layout'
import { Section } from '../components/Section'
import SEO from '../components/seo'
import { AboutQuery, Must } from '../types'

const AboutPage = ({ data }: PageProps<Must<AboutQuery>>) => (
  <Layout>
    <SEO title="About" />
    <Section>
      <Container as="article" className="lead">
        <Row>
          <Col sm={5}>
            <Heading>About</Heading>
            <p>
              Anita has two solid decades of fine tailoring under her belt, and she has a M.A. in
              Fashion Design from Goldsmiths, University of London. As Creative Director of her own
              eponymous label, she has developed her own signature look with a geometric finesse
              that adds a sophistication built around very exact processes of dress-making.
            </p>
            <p>
              She constantly thinks about drawing inspiration from different disciplines, and mixes
              them into her fashion practice. She is also a keen adopter of incorporating technology
              into her work, which always seeks to provide answers to modern living.
            </p>
          </Col>
          <Col sm={{ offset: 1, span: 6 }}>
            <Img fluid={data.photo.childImageSharp.fluid} alt="Anita Grey" />
          </Col>
        </Row>
      </Container>
    </Section>
    <Section variant="dark">
      <Container>
        <Heading as="h2" className="text-white">
          Exhibitions
        </Heading>
        <ListGroup>
          {data.exhibitions.edges.map(({ node }) => (
            <ListGroup.Item>
              <Badge variant="dark" className="mr-2">
                {node.date}
              </Badge>
              <strong>{node.title}</strong> {node.location}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Container>
    </Section>
  </Layout>
)

export default AboutPage

export const pageQuery = graphql`
  query About {
    photo: file(
      absolutePath: { regex: "/anita-grey-headshot.jpg/" }
      childImageSharp: { id: { ne: null } }
    ) {
      id
      childImageSharp {
        fluid(maxHeight: 540, maxWidth: 540) {
          ...GatsbyImageSharpFluid
        }
      }
      absolutePath
    }
    exhibitions: allExhibitionsYaml(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          date(formatString: "YYYY")
          location
        }
      }
    }
  }
`
